// Define global variables for JSHint
/* global Circuit */
/*exported LoginHelper*/

var LoginHelper = {};

(function () {
    'use strict';

    LoginHelper.AUTH_CODES = {
        BACKEND_SUCCESS: 0,
        BACKEND_CLOSED_CONNECTION: 1,
        BACKEND_MISSING_SERVER: 2,
        BACKEND_AUTHORIZATION_FAILED: 3,
        BACKEND_INCOMPATIBLE: 4,
        BACKEND_ERROR: 5,
        BACKEND_UNAVAILABLE: 6,
        SERVICE_EXCEPTION: 7,
        BACKEND_OVERLOAD: 8,
        OLD_VERSION: 9,
        BAD_REQUEST: 10,
        SERVICE_UNAVAILABLE: 11,
        USER_NOT_FOUND: 12,
        ACCOUNT_NOT_ACTIVATED: 13,
        OAUTH_ERROR: 14,
        INVALID_TENANT: 15,
        USER_NOT_CONFIGURED: 16,
        NO_USER_LICENSE: 17,
        BACKEND_ADMIN_AUTHORIZATION_FAILED: 18,
        SESSION_EXPIRING: 19,
        NEW_CONNECTION_DETECTED: 20,
        ACCOUNT_DISABLED: 21
    };

    LoginHelper.AUTH_RES = [
        'res_auth_Success',                  // 0 BACKEND_SUCCESS
        'res_auth_GenericLoginError',        // 1 BACKEND_CLOSED_CONNECTION
        'res_auth_GenericLoginError',        // 2 BACKEND_MISSING_SERVER
        'res_auth_GenericLoginError',        // 3 BACKEND_AUTHORIZATION_FAILED
        'res_auth_GenericLoginError',        // 4 BACKEND_INCOMPATIBLE
        'res_auth_GenericLoginError',        // 5 BACKEND_ERROR
        'res_auth_GenericLoginError',        // 6 BACKEND_UNAVAILABLE
        'res_auth_GenericLoginError',        // 7 SERVICE_EXCEPTION
        'res_auth_GenericLoginError',        // 8 BACKEND_OVERLOAD
        'res_auth_GenericLoginError',        // 9 OLD_VERSION
        'res_auth_GenericLoginError',        // 10 BAD_REQUEST
        'res_auth_GenericLoginError',        // 11 SERVICE_UNAVAILABLE
        'res_auth_InvalidUser',              // 12 USER_NOT_FOUND
        'res_auth_GenericLoginError',        // 13 ACCOUNT_NOT_ACTIVATED
        'res_auth_GenericLoginError',        // 14 OAUTH_ERROR
        'res_auth_InvalidTenant',            // 15 INVALID_TENANT
        'res_auth_InvalidUser',              // 16 USER_NOT_CONFIGURED
        'res_auth_NoLicenseError',           // 17 NO_USER_LICENSE
        'res_auth_AdminAuthorizationFail',   // 18 BACKEND_ADMIN_AUTHORIZATION_FAILED
        'res_auth_SessionExpiring',          // 19 SESSION_EXPIRING
        'res_SessionClosedNewConnection',    // 20 NEW_CONNECTION_DETECTED
        'res_auth_AccountDisabled'           // 21 ACCOUNT_DISABLED
    ];
}());
