
/* global __isProduction */
/* eslint-disable no-console */

var Circuit = (function (circuit) {
    'use strict';

    // In case a logger is already defined keep that one
    circuit.logger = circuit.logger || {
        debug: function () {
            if (!__isProduction) {
                console.log.apply(console, Array.prototype.slice.apply(arguments)); // NOSONAR
            }
        },
        info: function () {
            if (!__isProduction) {
                console.log.apply(console, Array.prototype.slice.apply(arguments)); // NOSONAR
            }
        },
        warning: function () {
            console.warn.apply(console, Array.prototype.slice.apply(arguments)); // NOSONAR
        },
        error: function (error, obj) {
            error = (error && error.stack) || error;
            obj = (obj && obj.stack) || obj;
            if (obj) {
                console.error(error, obj); // NOSONAR
            } else {
                console.error(error); // NOSONAR
            }
        }
    };

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
