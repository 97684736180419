var Circuit = (function (circuit) {
    'use strict';

    var logger = circuit.logger;
    logger.error = function (error, obj) {
        // eslint-disable-next-line no-console
        obj ? console.log(error, obj) : console.log(error);
    };
    logger.debug = function (msg, obj) {
        // eslint-disable-next-line no-console
        obj ? console.log(msg, obj) : console.log(msg);
    };
    logger.info = function (msg, obj) {
        // eslint-disable-next-line no-console
        obj ? console.log(msg, obj) : console.log(msg);
    };
    logger.warn = function (msg, obj) {
        // eslint-disable-next-line no-console
        obj ? console.log(msg, obj) : console.log(msg);
    };

    // Instantiate services either for guest or normal user
    function loadServices(guest, productName, data) {
        var services = {};
        logger = circuit.logger;

        var rootScopeFn = data.rootScope;
        rootScopeFn.isSessionGuest = !!guest;
        circuit.productName = productName || 'Unify Phone';
        rootScopeFn.isRebrandedSystem = circuit.productName !== 'Unify Phone';

        logger.info('[loginServicesLoader]: loadServices: guest = ' + rootScopeFn.isSessionGuest + ', productName = ' + circuit.productName);

        //---------------------------------------------------------------------------
        // Create the services.
        // The services should be created in the same order as the web client.
        //---------------------------------------------------------------------------
        loadCommonServices(data, services);

        loadNormalServices(data, services);

        circuit.serviceInstances = services;

        return true;
    }

    function loadCommonServices(data, services) {
        data = data || {};
        var rootScopeFn = data.rootScope;
        var promiseFn = data.promise;
        var timeoutFn = data.timeout;
        var httpFn = data.http;
        var popupFn = data.popupFn;

        try {
            services.logSvc = new circuit.LogSvcImpl(rootScopeFn, timeoutFn);
            logger.debug('[loginServicesLoader]: Instantiated LogSvc service');

            // UtilSvc
            services.utilSvc = new circuit.UtilSvcImpl(
                rootScopeFn,
                promiseFn,
                timeoutFn,
                logger);
            logger.debug('[loginServicesLoader]: Instantiated UtilSvc service');

            // PubSubSvc
            services.pubSubSvc = new circuit.PubSubSvcImpl(logger);
            logger.debug('[loginServicesLoader]: Instantiated PubSubSvc service');

            // HttpSvc
            services.httpSvc = new circuit.HttpSvcImpl(
                rootScopeFn,
                window,
                document,
                timeoutFn,
                httpFn,
                promiseFn,
                logger);
            logger.debug('[loginServicesLoader]: Created HttpSvc service');

            // LocalizeSvc
            services.localizeSvc = new circuit.LocalizeSvcImpl(
                rootScopeFn,
                window,
                null,   // $locale
                promiseFn,
                logger,
                services.utilSvc,
                services.pubSubSvc,
                services.httpSvc);
            logger.debug('[loginServicesLoader]: Instantiated LocalizeSvc service');

            // PopupSvc
            services.popupSvc = popupFn;
            logger.debug('[loginServicesLoader]: Created PopupSvc service');

        } catch (e) {
            logger.error('[loginServicesLoader]: Failed to create common service instances. ', e);
        }
    }


    // eslint-disable-next-line max-lines-per-function
    function loadNormalServices(data, services) { // NOSONAR
        data = data || {};
        var promiseFn = data.promise;
        var timeoutFn = data.timeout;
        var httpFn = data.http;

        // Load normal user services
        try {
            // LoginSvcImpl
            services.loginSvc = new circuit.LoginSvcImpl(
                location,
                httpFn,
                timeoutFn,
                promiseFn,
                logger);
            logger.debug('[loginServicesLoader]: Instantiated LoginSvc service');

        } catch (e) {
            logger.error('[loginServicesLoader]: Failed to create normal user specific service instances. ', e);
        }
    }

    circuit.initBL = function (data) {
        loadServices(false, 'Unify Phone', data);
    };

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
