/*global __signupInfo, LoginHelper*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var storeManager = circuit.storeManager;

    // These are the error codes defined in BackEndRes in server/js/clientApiProxy.js
    var BackEndRes = {
        BACKEND_SUCCESS: 0,
        BACKEND_CLOSED_CONNECTION: 1,
        BACKEND_MISSING_SERVER: 2,
        BACKEND_AUTHORIZATION_FAILED: 3,
        BACKEND_INCOMPATIBLE: 4,
        BACKEND_ERROR: 5,
        BACKEND_UNAVAILABLE: 6,
        SERVICE_EXCEPTION: 7,
        BACKEND_OVERLOAD: 8,
        OLD_VERSION: 9,
        BAD_REQUEST: 10,
        SERVICE_UNAVAILABLE: 11,
        USER_NOT_FOUND: 12,
        ACCOUNT_NOT_ACTIVATED: 13
    };

    // eslint-disable-next-line max-params, max-lines-per-function
    function LoginSvcImpl($location, $http, $timeout, $q, LogSvc) { // NOSONAR
        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var LocalStoreKey = {
            LAST_USERNAME: 'lastUserName',
            REGION: 'region',
            DOMAIN: 'domain',
            REMEMBER_ME: 'rememberMe',
            SSOLOGIN: 'ssologin',
            CACHE_ENABLED: 'cacheEnabled',
            ACCESSIBILITY_ENABLED: 'accessibilityEnabled',
            FORCED_SYSTEM: 'forcedSystem',
            DA_AUTO_UPDATE: 'daAutoUpdate'
        };

        var LOOKUP_VERSION = '1.0';

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getItem(key) {
            return storeManager.getItem(key);
        }

        function setItem(key, value) {
            storeManager.setItem(key, value);
        }

        function removeItem(key) {
            storeManager.removeItem(key);
        }

        function extractProviderFromResponse(data, type) {
            if (!data) {
                return null;
            }
            // Starting with SP130 the backend returns an object with tenantLogOnMethod property
            data = data.tenantLogOnMethod || data;
            if (!Array.isArray(data)) {
                return null;
            }

            type = type || 'web';

            for (var i = 0; i < data.length; i++) {
                try {
                    var providerRecord = JSON.parse(data[i]);
                    var applClients = providerRecord.applicableClients;

                    // Provider is applicable for all clients (null)
                    // or provider is applicable for a particular type (WEB or DESKTOP)
                    if (!applClients || applClients.toLowerCase().indexOf(type) !== -1) {
                        return providerRecord;
                    }
                } catch (e) {
                    if (data[i] && typeof data[i] === 'string') {
                        // Support for old lookup API
                        return { providerId: data[i] };
                    }
                }
            }

            return null;
        }

        function lookupFn(email, url, type, isTenant) {
            url = url || '';
            var sfx = isTenant ? '/tenant/lookup' : '/lookup';
            return $http({
                method: 'GET',
                url: url + sfx,
                params: {
                    email: email,
                    version: LOOKUP_VERSION,
                    ic: true
                }
            })
            .then(function (resp) {
                if (resp.status === 200) {
                    var lookupData = {
                        provider: extractProviderFromResponse(resp.data, type)
                    };
                    LogSvc.info('[LoginCtrl]: Received lookup data = ', lookupData);
                    return lookupData;
                } else {
                    return $q.reject(resp);
                }
            })
            .catch(function (e) {
                LogSvc.info('[LoginCtrl]: Failed to get lookup response: ', {
                    status: e.status,
                    statusText: e.statusText,
                    data: typeof e.data === 'object' ? e.data : null
                });
                return $q.reject(e);
            });
        }
        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        this.login = function (data, url) {
            return $http({
                method: 'POST',
                url: url,
                data: data,
                withCredentials: true
            });
        };

        this.lookupEmail = function (email, url, type) {
            return lookupFn(email, url, type);
        };

        this.lookupEmailV2 = function (data) {
            data = data || {};
            return lookupFn(data.email, data.url, data.type, data.isTenant);
        };

        this.getSignupErrorMessage = function (errorCode) {
            switch (errorCode) {
            case BackEndRes.BACKEND_SUCCESS:
                return '';
            case BackEndRes.BACKEND_OVERLOAD:
                return 'res_signup_StorageOverload';
            }
            return 'res_signup_ServiceUnavailable';
        };

        this.getLookupErrorMessage = function (err) {
            if (typeof err === 'number') {
                return LoginHelper.AUTH_RES[err];
            }

            var errValue = err && err.data && err.data.value;
            switch (errValue) {
            case BackEndRes.USER_NOT_FOUND:
                return 'res_EmailNotFound';
            case BackEndRes.ACCOUNT_NOT_ACTIVATED:
                return 'res_auth_AccountNotActivated';
            case BackEndRes.BACKEND_AUTHORIZATION_FAILED:
                return 'res_auth_AccountDisabled';
            default:
                return 'res_auth_BackendUnavailable';
            }
        };

        this.showLoginPage = function () {
            // Fade in the login page
            var el = $('.pagecontents');
            if (el && el.length) {
                el.stop().animate({opacity: 1});
            } else {
                $timeout(function () {
                    // eslint-disable-next-line newline-per-chained-call
                    $('.pagecontent').stop().animate({opacity: 1});
                }, 200);
            }
        };

        this.getMobileOSType = function () {
            if (/android/i.test(navigator.userAgent)) {
                return 'android';
            } else if (/iphone|ipad/i.test(navigator.userAgent)) {
                return 'ios';
            } else {
                return 'unknown';
            }
        };

        this.getItem = getItem;
        this.setItem = setItem;
        this.removeItem = removeItem;

        this.LocalStoreKey = LocalStoreKey;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.LoginSvcImpl = LoginSvcImpl;

    circuit.Enums = circuit.Enums || {};
    circuit.Enums.BackEndRes = BackEndRes;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
